<template>
  <!-- 审批流查询，分类汇总界面 -->
  <div class="wfClass">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <van-search
      v-model="value"
      placeholder="请输入搜索关键词"
      @input="onSearch"
    />

    <div class="applyCollapse">
      <van-collapse v-model="v.open" v-for="(v, k) in workflowlist" :key="k">
        <van-collapse-item name="a">
          <template #title>
            <div class="apply-collapse-text-box">
              <span class="apply-collapse-text">{{ v.formtype }}(</span>
              <span class="apply-collapse-text van-color-primary">{{
                v.shuliang
              }}</span>
              <span class="apply-collapse-text">)</span>
            </div>
          </template>
          <van-cell
            v-for="(value, key) in v.list"
            :key="key"
            @click="jump(value)"
            style="text-align: center"
            :title="value.appname"
            value=""
            :label="value.label"
            is-link
          >
            <svg-icon
              slot="icon"
              :icon-class="'plat' + value.appiconid"
            ></svg-icon>
          </van-cell>
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import {
  getActionWorkFlowClass,
  getMyWorkFlow,
  getMyCheckWorkFlow,
  getReturnWorkFlowCount,
  getNotPostClass,
  getCsListForClass,
  GetWorkflowInfo
} from '@/api/wx.js'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    const type = this.$route.query.type
    return {
      userInfo,
      value: '',
      type,
      workflowlist: [],
      iniworkflowlist: [],
      ini2: [],
      dwsp_caption: '待我审批',
      wtjd_caption: '未提交的',
      wfqd_caption: '我发起的',
      wysp_caption: '我已审批',
      bcxd_caption: '被撤销的',
      mydoc_caption: '我的单据',
      cs_caption: '抄送单据'
    }
  },
  computed: {
    title() {
      const arr = [
        '',
        this.dwsp_caption,
        this.wfqd_caption,
        this.wysp_caption,
        this.bcxd_caption,
        this.wtjd_caption,
        this.cs_caption
      ]
      return arr[Number(this.type)]
    }
  },
  created() {
    this.type = this.$route.query.type
    this.initData()
  },
  mounted() {
    setTimeout(() => {
      document.getElementsByClassName('van-tabbar-item')[0].style.color =
        '#646566'
      document.getElementsByClassName('van-tabbar-item')[1].style.color =
        '#1989fa'
    }, 200)
  },
  methods: {
    async initData() {
      // 审批流列表
      await GetWorkflowInfo({
        empcode: this.userInfo.empcode,
        langid: localStorage.getItem('langid')
      }).then(res => {
        if ('dwsp_caption' in res.forcheckcount[0]) {
          this.dwsp_caption = res.forcheckcount[0].dwsp_caption
          this.wfqd_caption = res.forcheckcount[0].wfqd_caption
          this.wysp_caption = res.forcheckcount[0].wysp_caption
          this.columns = [
            {
              id: 'wfqd_caption',
              text: this.wfqd_caption,
              icon: 'records'
            },
            {
              id: 'wysp_caption',
              text: this.wysp_caption,
              icon: 'completed'
            }
          ]
          this.bcxd_caption = res.forcheckcount[0].bcxd_caption
          this.wtjd_caption = res.forcheckcount[0].wtjd_caption || '未提交的'
          this.cs_caption = res.forcheckcount[0].cs_caption
          this.mydoc_caption = res.forcheckcount[0].mydoc_caption
          this.isworkflowgroup = res.forcheckcount[0].use_wb_wf_category
          localStorage.setItem(
            'dwsp_caption',
            res.forcheckcount[0].dwsp_caption
          )
          localStorage.setItem('cs_caption', res.forcheckcount[0].cs_caption)
          localStorage.setItem('wtjd_caption', this.wtjd_caption)
          localStorage.setItem(
            'wfqd_caption',
            res.forcheckcount[0].wfqd_caption
          )
          localStorage.setItem(
            'wysp_caption',
            res.forcheckcount[0].wysp_caption
          )
          localStorage.setItem(
            'bcxd_caption',
            res.forcheckcount[0].bcxd_caption
          )
          localStorage.setItem(
            'use_wb_wf_category',
            res.forcheckcount[0].use_wb_wf_category
          )
          
        }
      })
      switch (this.type) {
        case '1':
          // 待我审批
          await getActionWorkFlowClass({
            empcode: this.userInfo.empcode,
            langid: localStorage.getItem('langid')
          }).then(res => {
            this.bulid(res.data)
          })
          break
        case '2':
          // 我发起的
          await getMyWorkFlow({
            empcode: this.userInfo.empcode,
            langid: localStorage.getItem('langid')
          }).then(res => {
            this.bulid(res.data)
          })
          break
        case '3':
          // 我已审批
          await getMyCheckWorkFlow({
            empcode: this.userInfo.empcode,
            langid: localStorage.getItem('langid')
          }).then(res => {
            this.bulid(res.data)
          })
          break
        case '4':
          // 被撤销的
          await getReturnWorkFlowCount({
            empcode: this.userInfo.empcode,
            langid: localStorage.getItem('langid')
          }).then(res => {
            this.bulid(res.data)
          })
          break
        case '5':
          // 未提交的
          await getNotPostClass({
            empcode: this.userInfo.empcode,
            langid: localStorage.getItem('langid')
          }).then(res => {
            this.bulid(res.data)
          })
          break
        case '6':
          // 未提交的
          await getCsListForClass({
            empcode: this.userInfo.empcode,
            langid: localStorage.getItem('langid')
          }).then(res => {
            this.bulid(res.data)
          })
          break
        default:
          break
      }
    },
    // 构造分组列表
    bulid(data) {
      this.workflowlist = []
      let arr = [],
        formtype = ''
      data.forEach((e, i) => {
        switch (this.type) {
          case '1':
            // 待审批
            e.label = `${e.cnt}条${e.appname}待审批`
            break
          case '2':
            // 我发起
            e.label = `我发起了${e.cnt}条${e.appname}`
            break
          case '3':
            // 我已审
            e.label = `我审批了${e.cnt}条${e.appname}`
            break
          case '4':
            // 被撤销
            e.label = `${e.cnt}条${e.appname}被撤销`
            break
          case '5':
            // 未提交
            e.label = `${e.cnt}条${e.appname}待提交`
            break
          case '6':
            // 抄送
            e.label = `有${e.cnt}条待查看的抄送单据`
            break
          default:
            break
        }
        if (e.formtype != formtype) {
          formtype = e.formtype
          let obj = {
            formtype,
            open: ['a'], //  i == 0 ? ['a'] : [],
            list: []
          }
          obj.list = data.filter(item => item.formtype === formtype)
          arr.push(obj)
          console.log(e.formtype, formtype, arr)
        }
      })
      this.ini2 = JSON.parse(JSON.stringify(data))
      
      arr.forEach(item=>{
        if(item.list){
          let num = 0;
          item.list.forEach(value=>{
            num+=value.cnt
          })
          item.shuliang = num
        }
      })
      this.workflowlist = arr
      // console.log(this.workflowlist,'jkjfrkjfekjf')
      this.iniworkflowlist = JSON.parse(JSON.stringify(this.workflowlist))
      
    },
    onClickLeft() {
      this.$router.push({
        path: '/apply'
      })
    },
    onClickRight() {},
    onSearch(val) {
      if (val) {
        const list = this.ini2.filter(e => {
          return e.appname.indexOf(val) > -1
        })
        this.workflowlist = [
          {
            formtype: '搜索结果',
            open: ['a'], //  i == 0 ? ['a'] : [],
            list
          }
        ]
      } else {
        this.workflowlist = JSON.parse(JSON.stringify(this.iniworkflowlist))
      }
    },
    onCancel() {
      this.value = ''
      this.workflowlist = JSON.parse(JSON.stringify(this.iniworkflowlist))
    },
    jump(v) {
      console.log(v,'feeffefeff')
      this.$router.push({
        path: '/wfList',
        query: {
          type: this.type,
          formautoid: v.formautoid,
          appname: v.appname,
          appiconid: v.appiconid,
          mkeyname: v.mkeyname,
          mtablename: v.mtablename,
          mviewname: v.mviewname,
          show_total_score:v.show_total_score,
          total_score_title:v.total_score_title,
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wfClass {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .applyCollapse {
    width: 100%;
    background: #f2f2f2;
    padding: 10px;
    overflow-y: scroll;
    height: calc(100vh - 300px);
    .van-collapse {
      background: #fff;
      border-radius: 20px;
      padding: 10px;
      margin-bottom: 10px;
    }
    /deep/.apply-collapse-text {
      font-family: PingFangSC, PingFang SC;
      font-weight: 1000;
      color: #333333;
    }
    /deep/.van-collapse-item__content {
      padding: 0;
      .van-cell {
        display: flex;
        align-items: center;
        .svg-icon {
          width: 74px;
          height: 74px;
          margin-right: 12px;
        }
        .van-cell__title {
          text-align: left;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          span {
            display: block !important;
            width: 100% !important;
            line-height: 1 !important;
          }
        }
      }
    }
  }
}
</style>
