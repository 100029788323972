import { render, staticRenderFns } from "./wfClass.vue?vue&type=template&id=3126e488&scoped=true&"
import script from "./wfClass.vue?vue&type=script&lang=js&"
export * from "./wfClass.vue?vue&type=script&lang=js&"
import style0 from "./wfClass.vue?vue&type=style&index=0&id=3126e488&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3126e488",
  null
  
)

export default component.exports